import React from "react"
import { Link } from "gatsby"
import emailImage from "../../static/jkuahl4545sdf.jpg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Contact ☎️"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Contact"
          keywords={[`josh amore`, `joshamore`, `contact`]}
        />

        <p>
          Want to grab a coffee/beer, work together on an interesting project,
          or something else?
        </p>

        <p>
          <strong>My best contact method is below:</strong>
        </p>

        <img src={emailImage} alt="spoonville" />

        <p>
          The annoying format is an attempt to fight the spambots. All facts are
          true.
        </p>

        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
